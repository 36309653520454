$('.news-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: '<button class="icon-right next"></button>',
    prevArrow: '<button class="icon-left prev"></button>',
    responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
})


$('.projects-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: '<button class="icon-right next"></button>',
    prevArrow: '<button class="icon-left prev"></button>',
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1199.98,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767.98,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }    
        }

    ]
})