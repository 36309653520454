function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll');
    }else{
        $('header.site-header').removeClass('scroll');
    }
}

headerScroll();

$(window).scroll(headerScroll); 

$('.menu-btn').click(function(){
    $('.mobile-menu').fadeIn(300)
})

$('.close-menu').click(function(){
    $('.mobile-menu').fadeOut(300)
})